import { stripIgnoredCharacters, ASTNode } from 'graphql'
import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, useApolloClient } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import { ErrorHandler, ErrorResponse, onError } from '@apollo/client/link/error'
import { getCookie } from 'cookies-next'
import { formatInTimeZone } from 'date-fns-tz'
import { isSSR } from 'lib/helpers/isSSR/isSSR'
import { getLoginPath } from 'lib/navigation/helpers/getLoginPath/getLoginPath'
import { emitter, emitterEvents } from 'lib/emitter'
import { timeZones } from 'lib/consts/timeZones'
import { ERouterPage } from 'lib/navigation/consts'
import { cookieKeys } from 'lib/consts/browserStorage'
import { filterEmptyPurchaseDetails } from './helpers/filterEmptyPurchaseDetails/filterEmptyPurchaseDetails'
import autoMergeTypes from 'gql/auto-merge-types.json'
import { BillingPlanFeaturesEnumV2, OutboundOrder, OutboundOrderSourceEnum } from 'gql/types'
import { isBillingPlanFeaturesEnumV2 } from 'routes/[businessId]/helpers/isBillingPlanFeaturesEnumV2/isBillingPlanFeaturesEnumV2'

let inlinedBusinessId: string | null = null

const httpLink = createHttpLink({
  uri: `${process.env.BACKEND_APP_URL}/graphql`,
  print(ast: ASTNode, originalPrint: (ast: ASTNode) => string) {
    // Reduces query size by 25%
    return stripIgnoredCharacters(originalPrint(ast))
  },
})

const getFeatureGatingError = ({ graphQLErrors = [] }: ErrorResponse): BillingPlanFeaturesEnumV2 | null => {
  if (!graphQLErrors) {
    return null
  }

  const feature = graphQLErrors.find((gqlError) => gqlError?.extensions?.feature)?.extensions?.feature

  if (isBillingPlanFeaturesEnumV2(feature)) {
    return feature
  }

  return null
}

export const errorHandler: ErrorHandler = (errorResponse) => {
  const { networkError } = errorResponse
  const hasFeatureUpsellListeners = emitter.hasListeners(emitterEvents.featureUpsell)
  const featureGatingError = getFeatureGatingError(errorResponse)

  if (featureGatingError && hasFeatureUpsellListeners) {
    emitter.emit(emitterEvents.featureUpsell, featureGatingError)
  }

  if (networkError) {
    if ('statusCode' in networkError) {
      if (networkError.statusCode === 401) {
        if (!isSSR()) {
          if (window.document.location.pathname !== ERouterPage.login) {
            window.document.location.assign(getLoginPath({ next: window.document.location.pathname }))
          }
        }
      }
    }
  }
}

const errorLink = onError(errorHandler)

interface IRequestLinkParams {
  token?: Maybe<string>
  businessId?: Maybe<string>
}

const requestLink = ({ token }: IRequestLinkParams = {}) =>
  new ApolloLink((operation, forward) => {
    const authToken = token ?? getCookie(cookieKeys.token)

    if (authToken) {
      const headers: { [key: string]: string } = {
        Authorization: `Bearer ${authToken}`,
      }

      if (inlinedBusinessId) {
        headers['X-Business-ID'] = inlinedBusinessId
      }
      operation.setContext({ headers })
    }

    return forward(operation)
  })

// Arrays of data, where incoming array should completely substitute existed one.
// Those are queries that we're fetching without parameters.
const replaceableArrayQueryNames = [
  'businesses',
  'plaidConnections',
  'finicityConnections',
  'accounts',
  'invoicesFilteringOptions',
]

const replaceableArrays = replaceableArrayQueryNames.reduce<Record<string, { merge: false }>>((all, queryName) => {
  all[queryName] = {
    merge: false,
  }
  return all
}, {})

const singletoneTypes = ['NotificationSettings', 'XeroState', 'NetsuiteState', 'QuickBooksState', 'FinaloopState']

export const apolloCache = () =>
  new InMemoryCache({
    typePolicies: {
      Mergeable: {
        keyFields: false,
        merge: true,
      },
      NonMergeable: {
        keyFields: false,
        merge: false,
      },
      Singletone: {
        keyFields: [],
      },
      Location: {
        fields: {
          currentInventoryBalances: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      PurchaseOrder: {
        fields: {
          lineItems: {
            merge: (_, incoming) => incoming,
          },
          shippingVendors: {
            merge: (_, incoming) => incoming,
          },
          linkedDocuments: {
            merge: (_, incoming) => incoming,
          },
          additionalExpenses: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      Receipt: {
        fields: {
          shippingVendors: {
            merge: (_, incoming) => incoming,
          },
          lineItems: {
            merge: (_, incoming) => incoming,
          },
          linkedDocuments: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      InventoryItemDemandForecast: {
        keyFields: ['period', 'catalogItemVariation', ['id']],
        fields: {
          channelForecast: {
            merge: (_, incoming) => incoming,
          },
          manualForecast: {
            merge: (_, incoming) => incoming,
          },
          totalForecast: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      PurchaseOrderLineItem: {
        fields: {
          children: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      InvoiceLineItem: {
        fields: {
          children: {
            merge: (_, incoming) => incoming,
          },
          linkedPurchaseOrderExpenses: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      InventoryTransfer: {
        fields: {
          lineItems: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      CatalogItem: {
        fields: {
          barcodes: {
            merge: (_, incoming) => incoming,
          },
          properties: {
            merge: (_, incoming) => incoming,
          },
          variations: {
            merge: (_, incoming) => incoming,
          },
          salesDetails: {
            merge: (_, incoming) => incoming,
          },
          currentInventoryBalances: {
            merge: (_, incoming) => incoming,
          },
          purchaseDetails: {
            read: filterEmptyPurchaseDetails,
            merge: (_, incoming) => incoming,
          },
        },
      },
      DocumentsMatchingData: {
        fields: {
          matchedLines: {
            merge: (_, incoming) => incoming,
          },
          unmatchedBillLines: {
            merge: (_, incoming) => incoming,
          },
          unmatchedPurchaseOrderLines: {
            merge: (_, incoming) => incoming,
          },
          unmatchedReceiptLines: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      CatalogItemVariation: {
        fields: {
          barcodes: {
            merge: (_, incoming) => incoming,
          },
          propertyValues: {
            merge: (_, incoming) => incoming,
          },
          salesDetails: {
            merge: (_, incoming) => incoming,
          },
          currentInventoryBalances: {
            merge: (_, incoming) => incoming,
          },
          purchaseDetails: {
            read: filterEmptyPurchaseDetails,
            merge: (_, incoming) => incoming,
          },
        },
      },
      CatalogItemProperty: {
        fields: {
          values: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      OutboundOrder: {
        fields: {
          timelineEvents: {
            merge: (_, incoming) => incoming,
          },
          lineItems: {
            merge: (_, incoming) => incoming,
          },
          orderDate: {
            read: (existing: OutboundOrder['orderDate'], { readField }) => {
              const source = readField('source')

              if (existing && source === OutboundOrderSourceEnum.MANUAL) {
                return formatInTimeZone(new Date(existing), timeZones.utc, 'yyyy-MM-dd')
              }

              return existing
            },
          },
          targetFulfillmentDate: {
            read: (existing: OutboundOrder['targetFulfillmentDate'], { readField }) => {
              const source = readField('source')

              if (existing && source === OutboundOrderSourceEnum.MANUAL) {
                return formatInTimeZone(new Date(existing), timeZones.utc, 'yyyy-MM-dd')
              }

              return existing
            },
          },
        },
      },
      Invoice: {
        fields: {
          lineItems: {
            merge: (_, incoming) => incoming,
          },
          linkedDocuments: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      LocalBusiness: {
        fields: {
          vendorPaymentTerms: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      Journey: {
        keyFields: ['type'],
        fields: {
          steps: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      Business: {
        fields: {
          trackstarIntegrations: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      Query: {
        fields: {
          ...replaceableArrays,
          userRoles: {
            merge: (_, incoming) => incoming,
          },
          rules: {
            merge: (_, incoming) => incoming,
          },
          billingMethods: {
            merge: (_, incoming) => incoming,
          },
          journeys: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      Dashboard: {
        fields: {
          actionableItems: relayStylePagination(),
        },
      },
      ExternalItem: {
        fields: {
          children: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      PublicInvoice: {
        keyFields: ['uuid'],
      },
      BillingPlanFeatures: {
        keyFields: ['feature', 'supportedByPlan', 'usageData'],
      },
      JourneyStep: {
        keyFields: ['type', 'relatedObjectData'],
      },
      CodeTypeV2: {
        keyFields: ['referralCode'],
      },
      ApAgingBucketSummary: {
        keyFields: ['dayRange'],
      },
    },
    possibleTypes: {
      // Types that don't have id or uniq filed, that should be merged into one object.
      // In other words, only one instance of this type can exist in application
      Mergeable: autoMergeTypes,
      // Types that don't have id or uniq filed, that couldn't be merged into one object.
      // In other words, there are many instances of such types that are not connected to each other.
      // Those types won't be normalized by Apollo cache
      NonMergeable: ['QuickBooksDictionary'],
      SyncObject: ['Invoice', 'VendorCredit', 'CatalogItem', 'PurchaseOrder'],
      LinkedDocument: ['Invoice', 'PurchaseOrder', 'Receipt', 'DocumentReference'],
      Singletone: singletoneTypes,
      Integration: ['NetsuiteState', 'QuickBooksState', 'XeroState'],
    },
  })

export const setRequestLinkBusinessId = (id: typeof inlinedBusinessId): void => {
  inlinedBusinessId = id
}

type TInitializeApolloProps = IRequestLinkParams
export const initializeApollo = ({ token, businessId }: TInitializeApolloProps = {}) => {
  if (businessId) {
    setRequestLinkBusinessId(businessId)
  }

  const client = new ApolloClient({
    cache: apolloCache(),
    connectToDevTools: process.env.VERCEL_ENV !== 'production',
    link: ApolloLink.from([requestLink({ token }), errorLink, httpLink]),
  })

  return client
}
export type TApolloClient = ReturnType<typeof initializeApollo>
export type TApolloCache = ReturnType<typeof useApolloClient>['cache']
