import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import { GridColDef, GridColumnMenuItemProps } from '@mui/x-data-grid-premium'
import { useTranslation } from 'react-i18next'

interface IFilterDrawerItemProps extends GridColumnMenuItemProps {
  onFilterClick: (colDef: GridColDef) => void
}

export const FilterDrawerItem: React.FC<IFilterDrawerItemProps> = ({ onClick, onFilterClick, colDef }) => {
  const { t } = useTranslation()
  const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    onFilterClick(colDef)
    onClick(event)
  }

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        <FilterListIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{t('tableHeaderMenu::option::filter')}</ListItemText>
    </MenuItem>
  )
}
