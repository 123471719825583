import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const AutocompleteLoadingText = () => {
  const { t } = useTranslation()

  return (
    <Typography variant="body2" textAlign="center">
      {t('autocomplete::loading')}
    </Typography>
  )
}
